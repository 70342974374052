import { useState, useEffect, useRef } from 'react'
import CardHeader from 'components/Headers/CardHeader';
import { Spinner } from 'reactstrap'
import { Accordion, Image, Nav, NavItem, NavLink } from "react-bootstrap";
import { parse_date_time, base64ToUTF8String } from 'helpers/utils';
import ButtonVerify from 'components/Buttons/ButtonVerify';
import PaymentMethod from 'components/Payment/FlowPayment';
import CustomModal from "components/Modals/CustomModal";
import PhoneInput from 'react-phone-input-2';
import { getMobileOperatingSystem } from 'helpers/utils';
import { Constants } from 'helpers/Constants';
import UploadComponent from 'components/Loaders/UploadComponent';

const PAYMENT_CONFIG = JSON.parse(base64ToUTF8String(process.env.REACT_APP_PAYMENT_CONFIG));

function TermsAndConditionStep({ config, setIsCaptchaModal, isPaymentVerified, getConnection, setIsConfirmationModalOpen }) {

    const HandlePrivacyStatementCheck = (e) => {
        if (e.target.checked) {
            setIsCaptchaModal(true)
        }
    }

    const continueUnVerifyPolicy = (e) => {
        getConnection();
    }

    const confirmationAlert = (e) => {
        setIsConfirmationModalOpen(true);
    }

    return (
        <div>
            <div style={{ marginBottom: '15px' }}>
                <CardHeader title={config.verificationPipeline?.privacyStatementHeading} />
            </div>

            <div style={{ textAlign: "left", textSize: "12px" }}>

                <div className='privacy-page'>

                    <div data-bs-spy="scroll"
                        // className="priv-state"
                        data-bs-target="#list-example" data-bs-offset="0"
                        className="pl-3 pt-3 pr-3 custom-div-scroll1 privacy-bg-lightGray scrollspy-example priv-state"
                        tabIndex="0">
                        <p style={{ whiteSpace: "pre-line" }}>{config.verificationPipeline?.privacyStatementText}</p>
                    </div>

                    <div>
                        <input
                            // className="form-check-input priv-state" 
                            className="priv-state"
                            type="checkbox" value="" onChange={HandlePrivacyStatementCheck} id="flexCheckChecked" />
                        <label
                            // className="form-check-label list-check2 priv-state" 
                            className="priv-state"
                            htmlFor="flexCheckChecked">
                            <p className='m-0'>I have read and agree to the Privacy Statement</p>
                        </label>
                    </div>

                    {
                        isPaymentVerified && <div className="alert alert-primary m-0" role="alert">
                            <div>
                                You haven't verify your policy againt previous payment! Please continue to complete your verification.
                                <button type='button' onClick={continueUnVerifyPolicy} className='btn btn-primary mt-2 btn-sm ml-1' style={{ backgroundColor: '#1274AC' }}>Continue</button>
                                <button type='button' onClick={confirmationAlert} className='btn btn-danger mt-2 btn-sm'>Cancel</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function CategoryAndProductStep({ config, products, productsDetail, clearSelectedProducts, category, validateSelectedProduct, setSelectedCategory, selectedCategory }) {

    const [filterProducts, setFilterProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});

    const filterProductCategory = (cat_id) => {

        if (selectedCategory != cat_id) {
            clearSelectedProducts();
        }

        setSelectedCategory(cat_id);

        let filtered = [];
        if (cat_id == 0) {
            filtered = products;
        } else {
            filtered = products.filter((item) => {
                return item.categoryIds?.includes(cat_id);
            })
        }

        setFilterProducts(filtered);
    }

    useEffect(async () => {
        setFilterProducts(products);
    }, [products]);

    useEffect(() => {
        if (!selectedCategory && selectedCategory !== 0) return
        filterProductCategory(selectedCategory);
    }, [selectedCategory]);

    const selectedProductIds = productsDetail.map(({ _id }) => _id);

    const handleSelectCategory = (categoryId) => {
        if (categoryId === undefined) {
            clearSelectedProducts();
        }
        setSelectedCategory(categoryId);
    }

    return (
        <div className='cust-prod-page'>
            <div className='prod-title'>
                {
                    !isNaN(selectedCategory) ? (
                        <>
                            <div onClick={() => handleSelectCategory(undefined)} className='categories-back-button'>{"< Back"}</div>
                            <CardHeader title={config.verificationPipeline?.productsTypeTitle} />
                        </>
                    ) :
                        <CardHeader title={"Select Category"} />
                }
            </div>
            <div>
                {isNaN(selectedCategory) ? <Nav className='category-items'>
                    {category.sort((a, b) => a.categoryId - b.categoryId).map((item, index) => {
                        return (
                            <NavItem key={index} className={`${selectedCategory == item.categoryId ? 'active' : ''} category-item`} onClick={() => handleSelectCategory(item.categoryId)}>
                                <Image className='category-icon' src={item.categoryIconURL} />
                                <NavLink key={index} className={"category-item-name"} title={item.categoryName}>
                                    {item.categoryName}
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav> : filterProducts.map((item, index) => {
                    const categoryNames = item.categories.map(({ categoryName }) => categoryName);
                    return (
                        <div key={index} className="inputGroup">
                            <input checked={selectedProductIds.includes(item._id)} id={item.type + index} name={item.type} type={item.type}
                                onChange={((e) => validateSelectedProduct(e.target, item))} />
                            <label className={item.type} htmlFor={item.type + index}>
                                <h2>{item.productTitle}</h2>
                                <div className='label-subcontainer'>
                                    <p className='m-1 m-1-category'>{categoryNames.toString().replace(/,/g, ', ')}</p>
                                    <p className='m-1 m-1-price'>Price: MMK {item.price ? item.price : 0}</p>
                                    {/* <p className='m-1'><b>Description: </b>{item.decription}</p> */}
                                    <a className='m-1' onClick={() => setSelectedProduct(item)}>More Details</a>
                                </div>
                            </label>
                        </div>
                    )
                })}
                <CustomModal
                    confirmModal={() => { setSelectedProduct({}) }}
                    showModal={Object.keys(selectedProduct).length > 0}
                    hideModal={() => { setSelectedProduct({}) }}
                    headerText={selectedProduct?.productTitle}
                    alertClass='alert-info'
                    message={selectedProduct?.description ? selectedProduct?.description : "No Details Found"}
                    proceedBtnText="Ok"
                />
            </div>
        </div>
    )
}

function ProductStep({ config, products, productsDetail, clearSelectedProducts, category, validateSelectedProduct, activeTab, setActiveTab }) {

    const [filterProducts, setFilterProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});

    const filterProductCategory = (cat_id) => {

        if (activeTab != cat_id) {
            clearSelectedProducts();
        }

        setActiveTab(cat_id);

        let filtered = products.filter((item) => {
            return item.categoryIds?.includes(cat_id);
        })

        if (cat_id == 0) {
            filtered = products;
        }

        setFilterProducts(filtered);
    }

    useEffect(async () => {
        setFilterProducts(products);
        filterProductCategory(activeTab);
    }, [products]);

    const selectedProductIds = productsDetail.map(({ _id }) => _id);

    return (
        <div className='cust-prod-page'>
            <div className='prod-title'>
                <CardHeader title={config.verificationPipeline?.productsTypeTitle} />
            </div>
            <div className="wrap-box">
                <Nav>
                    <NavItem>
                        <NavLink className={activeTab == '0' ? 'active' : ''} onClick={() => filterProductCategory('0')}>
                            All
                        </NavLink>
                    </NavItem>
                    {category.map((item, index) => {
                        return (
                            <NavItem key={index}>
                                <NavLink key={index} className={activeTab == item.categoryId ? 'active' : ''} onClick={() => filterProductCategory(item.categoryId)}>
                                    {item.categoryName}
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>
                {filterProducts.map((item, index) => {
                    const categoryNames = item.categories.map(({ categoryName }) => categoryName);
                    return (
                        <div key={index} className="inputGroup">
                            <input checked={selectedProductIds.includes(item._id)} id={item.type + index} name={item.type} type={item.type}
                                onChange={((e) => validateSelectedProduct(e.target, item))} />
                            <label className={item.type} htmlFor={item.type + index}>
                                <h2>{item.productTitle}</h2>
                                <div className='label-subcontainer'>
                                    <p className='m-1 m-1-category'>{categoryNames.toString().replace(/,/g, ', ')}</p>
                                    <p className='m-1 m-1-price'>Price: MMK {item.price ? item.price : 0}</p>
                                    {/* <p className='m-1'><b>Description: </b>{item.decription}</p> */}
                                    <a className='m-1 m-1-more-details' onClick={() => setSelectedProduct(item)}>More Details</a>
                                </div>
                            </label>
                        </div>
                    )
                })}
                <CustomModal
                    confirmModal={() => { setSelectedProduct({}) }}
                    showModal={Object.keys(selectedProduct).length > 0}
                    hideModal={() => { setSelectedProduct({}) }}
                    headerText={selectedProduct?.productTitle}
                    alertClass='alert-info'
                    message={selectedProduct?.description ? selectedProduct?.description : "No Details Found"}
                    proceedBtnText="Ok"
                />
            </div>
        </div>
    )
}

function PhoneNumberStep({ config, phoneNo, setPhoneNo }) {

    const handleChange = (e) => {
        setPhoneNo('+' + e);
    }

    return (
        <div>
            <div className='phn-title'>
                <CardHeader title={config.verificationPipeline?.phoneHeading} />
            </div>

            <div className="input-box1">
                <PhoneInput
                    inputStyle={{ width: '100%', height: "50px", boxShadow: "1px 1px 5px 0px" }}
                    country={"mm"}
                    onChange={handleChange}
                    value={phoneNo}
                />
            </div>
        </div>
    )
}

function ConnectionStep({ config, connectionPollingSpinner, getConnection }) {

    const { verificationPipeline } = config;
    const buttonColor = verificationPipeline?.buttonColor;
    const { qrCodeUrl } = config.connectionRequestsConfig;

    const tryAgain = () => {
        getConnection();
    }

    const onDownloadButtonClicked = (e) => {
        let os = getMobileOperatingSystem();
        let url = 'https://zada.io/app/';
        if (os === 'Android') {
            url = Constants.ANDROID_URL
        }

        if (os === 'iOS') {
            url = Constants.IOS_URL
        }

        if (url !== '') {
            window.location.href = url;
        }


    }

    return (
        <div className='text-center'>
            <div className="qrcode-description" >
                <CardHeader className="mb-1 qrcode-description" title={config.verificationPipeline?.qrCodeHeading} />
            </div>

            <button className='deep-link-btn'
                onClick={() => window.location.assign(config?.connectionRequestsConfig?.deepLinkUrl)}
                style={{
                    backgroundColor: buttonColor,
                    borderColor: buttonColor,
                    borderRadius: "5px",
                    padding: "0px 15px",
                    fontSize: "15px",
                    color: '#fff',
                }}>
                Open in ZADA Wallet App
            </button>

            <div className='my-3'>
                <p>OR</p>
            </div>

            <h6>Scan QR Code with your ZADA Wallet app</h6>

            <div className='my-4'>
                <div className="qr-edges-box">
                    <img className="img-logo" src={qrCodeUrl} />
                </div>
            </div>

            <div className='my-3'>
                {connectionPollingSpinner ?
                    <Spinner animation="grow" /> :
                    <button className='ctrl-btn-next try-btn' style={{
                        backgroundColor: buttonColor,
                        borderColor: buttonColor,
                        borderRadius: "0px",
                        padding: "0px 10px",
                        color: '#fff',
                    }} onClick={tryAgain}>Try Again</button>}

            </div>

            <h5 className='my-3'>Waiting for the connection to established . . .</h5>

            <div>
                <button
                    onClick={onDownloadButtonClicked}
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        borderRadius: "5px",
                        padding: "0px 15px",
                        fontSize: "15px",
                        color: "red",
                    }}>
                    Don't have the ZADA wallet App? Click here to download
                </button>
            </div>
            <div>
                <p>Create an account and then come back here to finish the process.</p>
            </div>
        </div>
    )
}

let verificationCount = 0;
function VerifyPoliciesStep({ config, connection, policiesList, setProofData, proofData, availPayment }) {
    let [isVerificationSend, setIsVerificationSend] = useState(false);
    let [isVerificationAvail, setIsVerificationAvail] = useState(false);

    useEffect(async () => {
        if (isVerificationAvail && verificationCount == 0) {
            verificationCount = 1;
            availPayment();
        }
    }, [isVerificationSend]);

    return (
        <div>
            <div className='policies-title'>
                <CardHeader title={config.verificationPipeline?.policiesHeading} />
            </div>
            <div style={{ textAlign: "left", textSize: "12px" }}>
                {config.verificationPipeline?.policySubtitle && <div data-bs-spy="scroll"
                    data-bs-target="#list-example" data-bs-offset="0"
                    className="pl-3 pt-3 pr-3 custom-div-scroll1 privacy-bg-lightGray scrollspy-example priv-state"
                    tabIndex="0">
                    <p style={{ whiteSpace: "pre-line" }}>{config.verificationPipeline?.policySubtitle}</p>
                </div>}
                <ul className='p-0'>
                    {policiesList.length > 0 && policiesList.map((policy, index) => {
                        return (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center policy-title">
                                {policy.policyName}{policy.isOptional && " (Optional)"}

                                <ButtonVerify
                                    policy={policy}
                                    connection={connection}
                                    isVerificationSend={isVerificationSend}
                                    setIsVerificationSend={setIsVerificationSend}
                                    setIsVerificationAvail={setIsVerificationAvail}
                                    setProofData={setProofData}
                                    proofData={proofData}
                                />

                            </li>)
                    })}
                </ul>
            </div>

            {policiesList.length == 0 &&
                <div className="alert alert-warning" role="alert">
                    No Policy Available Against Selected Product...
                </div>
            }

            <div className='policies-connectionless-link'>
                {/* <p onClick={onPressPrevious}>Do not have a connection with <b> {serverConfig.tenantName}</b> ? Click here to create it.</p> */}
            </div>

        </div>
    )
}

function VerificationSummaryStep({ config, proofData, productsDetail, policiesQuestions, connection, isPaymentVerified }) {
    let title = isPaymentVerified ? 'SUMMARY' : config.verificationPipeline?.summarizeInformationHeading;
    return (
        <div styles={{ height: '500px' }} >

            <div className='summary-title'>
                <CardHeader title={title} />
            </div>

            {
                isPaymentVerified && <div className="alert alert-success mb-4" role="alert">
                    <div>
                        Payment Successfully Done!
                    </div>
                </div>
            }

            <ul className="list-group list-group-flush" style={{ textAlign: "left" }}>

                <li className="list-group-item"><b style={{ marginRight: "45px" }}>Name:</b> {connection.name}</li>
                <li className="list-group-item"><b style={{ marginRight: "55px" }}>Phone:</b> {connection.phone}</li>

                <Accordion defaultActiveKey="0" flush className='accordian-data'>

                    <Accordion.Item eventKey="0">
                        <Accordion.Header> <b>Products Detail</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ textAlign: "left" }} data-bs-spy="scroll" data-bs-target="#list-example" data-bs-offset="0" className="custom-div-scroll2 scrollspy-example" tabIndex="0">

                                {productsDetail.length > 0 && productsDetail.map((item, index) => {
                                    return (<div key={index}>
                                        <p className='prod-detail-data'><b style={{ marginRight: "10px", fontWeight: "700" }}>Product Title:</b> <b style={{ fontWeight: "600" }}>{item.productTitle}</b></p>
                                    </div>)
                                })}
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" className='accordian-data'>
                        <Accordion.Header><b>Proofs</b></Accordion.Header>

                        <Accordion.Body>

                            <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-offset="0" className="custom-div-scroll2 scrollspy-example" tabIndex="0">

                                {proofData.length > 0 && proofData.map((item, index) => {
                                    const _policyId = Object.keys(item)[0]
                                    return (<div key={index}>
                                        <p className='proofs-detail-data' ><b style={{ marginRight: "10px", fontWeight: "700" }}>Name:</b>
                                            <b style={{ fontWeight: "600" }}>{item[_policyId]?.policyName}</b>
                                        </p>
                                        <p className='proofs-detail-data' >
                                            <b style={{ marginRight: "10px", fontWeight: "700" }}>Data:</b>
                                        </p>
                                        {
                                            Object.entries(item[_policyId]?.data).map(([item1Key, item1Val]) => {
                                                return (<div key={item1Key}>
                                                    <p className='proofs-detail-data' >
                                                        <b style={{ marginRight: "10px", marginLeft: "40px", fontWeight: "700" }}>{item1Key}:</b>
                                                        <b style={{ fontWeight: "600" }}> {parse_date_time(item1Val)}</b>
                                                    </p>
                                                </div>)
                                            })
                                        }
                                    </div>)
                                })}
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2" className='accordian-data'>
                        <Accordion.Header><b>Questions</b></Accordion.Header>
                        <Accordion.Body>
                            <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-offset="0" className="custom-div-scroll2 scrollspy-example" tabIndex="0">

                                {policiesQuestions.length > 0 && policiesQuestions.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <p className='proofs-detail-data' >
                                                <b style={{ marginRight: "10px", fontWeight: "700" }}>{i + 1}: {item.question}</b>
                                                <br />
                                                <b style={{ fontWeight: "600" }}>Answer: {item.answer}</b>
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </ul>
        </div>
    )
}

const PolicyQuestionsStep = ({ policiesQuestions, setPoliciesQuestions, isPaymentVerified, setIsConfirmationModalOpen }) => {

    const questions = policiesQuestions;
    const itemsRef = useRef([]);
    const [files, setFiles] = useState({});
    const [errors, setErrors] = useState([]);

    const inputTypes = {
        string: "text",
        date: "date",
        datetime_local: "datetime-local",
        select: 'select',
        photo: 'file'
    }

    const collectAnswer = (answer, data, index, metadata) => {
        if (answer.match(data.pattern) != null) {
            itemsRef.current[index].style.borderColor = "#ced4da"
        } else {
            itemsRef.current[index].style.borderColor = "red"
        }

        if (metadata) {
            data.metadata = metadata
            let maxFileSize = 5000000;
            if (metadata.size > maxFileSize) {
                itemsRef.current[index].style.borderColor = "red"
                itemsRef.current[index].value = '';
                setErrors({ ...errors, [index]: 'File size is too large. Please upload file less than 5MB.' })
                return;
            } else {
                setErrors({ ...errors, [index]: '' })
            }
            setFiles({ ...files, [index]: metadata });
            data.uploaded = false;
        };

        data.answer = answer;
        setPoliciesQuestions(questions);
    }

    return (
        <div className="questions-container">
            <div className='prod-title'>
                <CardHeader title={'Questions'} />
            </div>
            <div>
                {questions.map((item, i) => {
                    return (
                        <div key={item.question + "" + i} className="text-left mb-3" >
                            <label className="check-label question-text" htmlFor={'q_' + i}>
                                {i + 1 + '. ' + item.question} {item.mandatory && <font color="red">*</font>}
                            </label>
                            {item.type == "select" ?
                                <select
                                    ref={el => itemsRef.current[i] = el}
                                    className='form-control'
                                    id={'q_' + i}
                                    name={'q_' + i}
                                    onChange={((e) => {
                                        collectAnswer(e.target.value, item, i);
                                    })}
                                    defaultValue={item.answer}
                                >
                                    <option selected disabled>Select Option</option>
                                    {item?.options && item?.options?.map((e, key) => {
                                        return <option selected={e == item?.answer ?? item?.default} key={key} value={e}>{e}</option>;
                                    })}
                                </select> : <input
                                    id={'q_' + i}
                                    key={'q_' + i}
                                    name={'q_' + i}
                                    type={inputTypes[item.type]}
                                    accept={'.jpg,.jpeg,.png,.heic'}
                                    defaultValue={item.answer}
                                    onChange={((e) => {
                                        if (inputTypes[item.type] != 'file') {
                                            collectAnswer(e.target.value, item, i);
                                        } else {
                                            collectAnswer(e.target.value.split('\\').pop().split('/').pop(), item, i, e.target.files[0]);
                                        }
                                    })}

                                    className="form-control"
                                    style={{ color: "#007CC7", borderColor: "blue" }}
                                    placeholder="Answer"

                                    ref={el => itemsRef.current[i] = el}

                                />
                            }
                            {inputTypes[item.type] == 'file' && (
                                <UploadComponent file={files[i]} question={item} setPoliciesQuestions={setPoliciesQuestions} />
                            )}
                            {errors[i] && errors[i].length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    {errors[i]}
                                </div>
                            )}
                        </div>
                    )
                })}
                {questions.length == 0 &&
                    <div className="alert alert-warning" role="alert">
                        No Question Available Against Selected Policy...
                    </div>
                }
            </div>
        </div>
    )
}

const PaymentStep = ({ config, phoneNo, productsDetail, setIsPaymentVerfied, setIsPaymentWindowClosed, setPaymentData, setIsConfirmationModalOpen }) => {

    const PRICE_TYPE = PAYMENT_CONFIG?.PAYMENT_PRICE_TYPE;

    const SUB_TOTAL = PRICE_TYPE == "PRODUCT" ? productsDetail.reduce((a, b) => a + (b.price || 0), 0) : PAYMENT_CONFIG?.PAYMENT_AMOUNT;
    const TOTAL = PRICE_TYPE == "PRODUCT" ? productsDetail.reduce((a, b) => a + (b.price || 0), 0) : PAYMENT_CONFIG?.PAYMENT_AMOUNT;
    const selectedProductIds = productsDetail.map(({ _id }) => _id);

    const confirmationAlert = (e) => {
        setIsConfirmationModalOpen(true);
    }

    return (
        <div>
            <div className='prod-title'>
                <CardHeader title={'Payment'} />
            </div>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 ">
                            <table className="table table-responsive table-hover w-100">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Qty</th>
                                        <th className="text-center">Price</th>
                                        <th className="text-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productsDetail.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-md-9"><h4><em>{item.productTitle}</em></h4></td>
                                                <td className="col-md-1 text-center">1</td>
                                                <td className="col-md-1 text-center">{item.price ? item.price : 0}</td>
                                                <td className="col-md-1 text-center">{item.price ? item.price : 0}</td>
                                            </tr>
                                        )
                                    })}

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">
                                            <p>
                                                <strong>Subtotal: </strong>
                                            </p>
                                        </td>
                                        <td className="text-center">
                                            <p>
                                                <strong>MMK {SUB_TOTAL}</strong>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right"><h4><strong>Total: </strong></h4></td>
                                        <td className="text-center text-danger"><h4><strong>MMK {TOTAL}</strong></h4></td>
                                    </tr>
                                </tbody>
                            </table>

                            {
                                TOTAL == 0 &&
                                <div className="alert alert-danger m-0" role="alert">
                                    <div>
                                        You cannot be proceed due to 0 total.
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    TOTAL > 0 &&
                    <div className="container payment-method-box">
                        <PaymentMethod phoneNo={phoneNo} setIsPaymentVerfied={setIsPaymentVerfied} setPaymentData={setPaymentData} setIsPaymentWindowClosed={setIsPaymentWindowClosed} data={selectedProductIds} price_type={PRICE_TYPE} />
                    </div>
                }
                {
                    <div className="container">
                        <div className="alert alert-warning mt-5" role="alert">
                            <div className='text-right'>
                                <button type='button' onClick={confirmationAlert} className='btn btn-danger ml-1 btn-sm'>Cancel Verification</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export {
    TermsAndConditionStep,
    ProductStep,
    PhoneNumberStep,
    ConnectionStep,
    VerifyPoliciesStep,
    VerificationSummaryStep,
    PolicyQuestionsStep,
    PaymentStep,
    CategoryAndProductStep
};