import React from 'react'
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";

const PaymentModal = ({ showModal, hideModal, paymentUrl }) => {
    return (
        <Modal isOpen={showModal} onHide={hideModal} centered>
            <h6 className='text-right pr-4 pt-3 pb-2'>
                <span onClick={hideModal} className='cursor-pointer payment-modal-close-btn'>X</span>
            </h6>
            <ModalBody className='p-0'>
                <div>
                    <iframe name="my-iframe" style={{ width: "100%", height: '70vh' }} id="iframe1" src={paymentUrl} />
                </div>
            </ModalBody>
        </Modal>
    )
}

export default PaymentModal;