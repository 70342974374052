import Auth from 'helpers/Auth'
import axios from 'axios'

class PaymentServicesClass {
    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Auth.getToken()
        };
    }

    // generateWavePaymentLinkMeta = (data) => {

    //     return new Promise((resolve, reject) => {
    //         try {
    //             var WAVE_PAYMENT_API_PATH = process.env.REACT_APP_API_URL + '/api/generateWavePaymentLinkMeta'

    //             axios.get(WAVE_PAYMENT_API_PATH, {
    //                 params: data,
    //                 headers: this.headers,
    //             })
    //                 .then((json) => {
    //                     resolve(json)
    //                 })

    //         } catch (error) {
    //             reject(error)

    //             console.log(error)
    //         }
    //     })
    // }

    // generate2c2pPaymentLink = async (data) => {

    //     return new Promise((resolve, reject) => {
    //         try {
    //             const OTHER_PAYMENT_API_PATH = `${process.env.REACT_APP_API_URL}/api/generatePaymentLink`

    //             axios.get(OTHER_PAYMENT_API_PATH, {
    //                 params: data,
    //                 headers: this.headers,
    //             })
    //                 .then((json) => {
    //                     resolve(json)
    //                 })

    //         } catch (error) {
    //             console.log(error)
    //             reject(error)
    //         }
    //     })
    // }

    isPaymentVerified = async () => {

        return new Promise((resolve, reject) => {
            try {
                const CHECK_PAYMENT_API_PATH = `${process.env.REACT_APP_API_URL}/api/isPaymentVerified`

                axios.get(CHECK_PAYMENT_API_PATH, {
                    params: {
                        invoiceNo: localStorage.getItem('invoiceNo')
                    },
                    headers: this.headers,
                })
                    .then((json) => {
                        resolve(json)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    setPaymentVerificationAvail = async () => {

        return new Promise((resolve, reject) => {
            try {
                const AVAIL_PAYMENT_API_PATH = `${process.env.REACT_APP_API_URL}/api/avail_payment`

                axios.post(AVAIL_PAYMENT_API_PATH,
                    {
                        invoiceNo: localStorage.getItem('invoiceNo')
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    generateDingerPaymentLink = async (data) => {
        try {
            return new Promise((resolve, reject) => {
                try {
                    const DINGER_PAYMENT_API_PATH = `${process.env.REACT_APP_API_URL}/api/generateDingerPaymentLink`

                    axios.post(DINGER_PAYMENT_API_PATH,
                        {
                            data,
                        },
                        {
                            headers: this.headers
                        }).then((json) => {
                            resolve(json)
                        }).catch((e) => {
                            console.log({ e })
                            resolve(false)
                        })

                } catch (error) {
                    console.log(error)
                    reject(error)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

}

const PaymentServices = new PaymentServicesClass;

export default PaymentServices;
