import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const CustomModal = ({
    headerText,
    showModal,
    hideModal,
    confirmModal,
    id,
    message,
    alertClass,
    proceedBtnText,
    loading,
}) => {
    return (
        <Modal isOpen={showModal} onHide={hideModal} centered>
            <ModalHeader
                closeButton
                cssModule={{ 'modal-title': 'w-100 text-center' }}
            >
                <h3>{headerText ? headerText : ''}</h3>
            </ModalHeader>
            <ModalBody>
                <div className={`alert ${alertClass}`}>{message}</div>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={loading}
                    color="info"
                    onClick={() => (id ? confirmModal(id) : confirmModal())}
                >
                    {proceedBtnText ? proceedBtnText : 'OK'}{' '}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CustomModal
