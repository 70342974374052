const jwt = require("jsonwebtoken");

const Auth = {
    authenticate(token, exp) {
        localStorage.setItem('itoken', token);
        localStorage.setItem('exp', exp);
    },

    signout() {
        localStorage.removeItem('itoken');
        localStorage.removeItem('name');
        localStorage.removeItem('image');
        localStorage.removeItem('firstLoad');
        localStorage.removeItem('exp');
        localStorage.removeItem('admin_center');
        localStorage.removeItem('tenant');
        localStorage.removeItem('table');
    },

    getAuth() {
        //TODO: verify token for more security
        try {
            // let token = localStorage.getItem('itoken');
            // let decodedToken = jwt.decode(token);
            // let tokenRole = decodedToken['role'];
            // return tokenRole;
            //  console.log("Tenant: ",process.env.REACT_APP_TENANT_ID)
            // console.log("secret: ",process.env.REACT_APP_API_SECRET)
            var exp = String(Math.floor(Date.now() / 1000) + (120))
            var token = jwt.sign({ sub: process.env.REACT_APP_TENANT_ID }, process.env.REACT_APP_API_SECRET, { expiresIn: '600s' });
            // var token = jwt.sign({ sub: process.env.REACT_APP_TENANT_ID }, process.env.REACT_APP_API_SECRET, { expiresIn: '120s' });
            //   console.log("token: ",token);
            this.authenticate(token, exp)
            return token;
        } catch (error) {
            console.log(error.message)
            return undefined;
        }
    },

    getToken() {
        let nowUnixEpoch = Math.round(Date.now() / 1000);
        //   console.log("nowUnix: ",nowUnixEpoch)
        //   console.log("exp val: ",localStorage.getItem('exp'))
        //  let expUnixEpoch = Number(localStorage.getItem('exp').split(/(\d+)/)[1]);
        let expUnixEpoch = Number(localStorage.getItem('exp'));

        //    console.log("expUnix: ",expUnixEpoch)
        //    console.log("expUnix - nowUnix ",expUnixEpoch-nowUnixEpoch)

        if ((expUnixEpoch - nowUnixEpoch) <= 1) {
            //  console.log("Inside")
            return this.getAuth();
        } else {
            return localStorage.getItem('itoken');
        }
    }

};

export default Auth;