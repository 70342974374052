import { useState, createRef } from "react"
import ReCAPTCHA from 'react-google-recaptcha';
import { Modal } from "react-bootstrap";



const CaptchaModal = ({ isCaptchaModal, setIsCaptchaModal, setPrivacyStatementCaptcha }) => {

    const recaptchaRef = createRef();

    const HandleRecaptcha = () => {

        const recaptchaValue = recaptchaRef.current.getValue();

        if (recaptchaValue) {
            
            setPrivacyStatementCaptcha(true);

            setTimeout(function () {
                setIsCaptchaModal(false);
            }, 1000);

        }

    }


    return (
        <Modal
            size="sm"
            show={isCaptchaModal}
            aria-labelledby="contained-modal-title-vcenter-sm"
            className='recaptcha-modal'
            centered
        >
            <Modal.Header>
                <div>
                    Verify that you are a Human Being
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='recaptcha-comp'>
                    <ReCAPTCHA
                        className='recaptcha-comp'
                        sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                        ref={recaptchaRef}
                        onChange={HandleRecaptcha}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default CaptchaModal;