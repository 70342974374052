import { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
    randomCode,
    randomNumber,
    base64ToUTF8String
} from "helpers/utils";
import PaymentServices from 'services/Payment';
import io from "socket.io-client";
import PaymentModal from "components/Modals/PaymentModal";


var paymentUrl = '';

function PaymentMethod(props) {

    const PAYMENT_CONFIG = JSON.parse(base64ToUTF8String(process.env.REACT_APP_PAYMENT_CONFIG));

    const wavePaymentURL = PAYMENT_CONFIG.REACT_APP_WAVE_URL;

    const [waveMetadata, setWaveMetadata] = useState({});
    const [paymentRequestLoading, setPaymentRequestLoading] = useState(false);

    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const formWave = useRef();

    const othersBtnRef = useRef();
    const officialPartner1BtnRef = useRef();
    const officialPartner2btnRef = useRef();

    // const onOthersBtnClick = () => {
    //     if (othersBtnRef.current) {
    //         if (!(othersBtnRef.current.getAttribute("disabled"))) {
    //             othersBtnRef.current.setAttribute("disabled", "disabled");
    //             setPaymentRequestLoading(true);
    //             loadOther();
    //         }
    //     }
    // }

    // const onOfficialPartner1BtnClick = () => {
    //     if (officialPartner1BtnRef.current) {
    //         if (!(officialPartner1BtnRef.current.getAttribute("disabled") == "disabled")) {
    //             officialPartner1BtnRef.current.setAttribute("disabled", "disabled");
    //             setPaymentRequestLoading(true);
    //             loadWave();
    //         }
    //     }
    // }

    // const onOfficialPartner2BtnClick = () => {
    //     if (officialPartner2btnRef.current) {
    //         if (!(officialPartner2btnRef.current.getAttribute("disabled") == "disabled")) {
    //             officialPartner2btnRef.current.setAttribute("disabled", "disabled");
    //             setPaymentRequestLoading(true);
    //             load2c2p();
    //         }
    //     }
    // }

    const enableAllPaymentOptions = () => {
        [othersBtnRef, officialPartner1BtnRef, officialPartner2btnRef].forEach((btnRef) => {
            setPaymentRequestLoading(false);
            btnRef.current && btnRef.current.removeAttribute("disabled");
        });
    }

    // var loadWave = async () => {
    //     try {
    //         let resp = await getWavePaymentLinkData();
    //         if (resp && resp.status === 200) {
    //             let waveMeta = resp.data.response;

    //             paymentUrl = wavePaymentURL;
    //             // setShowPaymentModal(true);

    //             waveMeta.items = JSON.stringify(waveMeta.items);
    //             setWaveMetadata(waveMeta);

    //             formWave.current && formWave.current.submit();

    //         } else {
    //             setPaymentRequestLoading(false);
    //             enableAllPaymentOptions()
    //             console.log("Show error!");
    //         }
    //     } catch (e) {
    //         setPaymentRequestLoading(false);
    //         enableAllPaymentOptions()
    //         console.log({ e })
    //     }
    // };

    // var load2c2p = async () => {
    //     try {
    //         let resp = await generate2c2pPaymentLink();
    //         if (resp && resp.data.status === 200) {

    //             paymentUrl = resp.data.response.webPaymentUrl;
    //             setShowPaymentModal(true);

    //         }
    //         else {
    //             console.log("Show error!");
    //             setPaymentRequestLoading(false);
    //             enableAllPaymentOptions()
    //         }
    //     } catch (error) {
    //         setPaymentRequestLoading(false);
    //         enableAllPaymentOptions()
    //         console.log(error);
    //     }
    // };

    // var loadOther = async () => {
    // try {
    //     let resp = await generate2c2pPaymentLink();
    //     if (resp.data.status === 200) {

    //         paymentUrl = resp.data.response.webPaymentUrl;
    //         setShowPaymentModal(true);

    //     } else {
    //         setPaymentRequestLoading(false);
    //         enableAllPaymentOptions()
    //         console.log("Show error!");
    //     }
    // } catch (error) {
    //     setPaymentRequestLoading(false);
    //     enableAllPaymentOptions()
    //     console.log(error);
    // }
    // };

    const loadDinger = async () => {
        try {
            let resp = await generateDingerPaymentLink();
            if (resp.data.status === 200) {

                paymentUrl = resp.data.response;
                setShowPaymentModal(true);

            } else {
                setPaymentRequestLoading(false);
                enableAllPaymentOptions()
                console.log("Show error!");
            }
        } catch (error) {
            setPaymentRequestLoading(false);
            enableAllPaymentOptions()
            console.log(error);
        }
    };

    const generateDingerPaymentLink = async () => {
        let invoiceNo = moment().format("MMDDYY") + randomNumber(6) + randomCode(6);
        localStorage.setItem("invoiceNo", invoiceNo);
        var response;

        try {
            let data = {
                invoiceNo,
                data: props.data,
                price_type: props.price_type,
                phone: props.phoneNo
            };

            response = await PaymentServices.generateDingerPaymentLink(data);

        } catch (error) {
            console.error(error);
            enableAllPaymentOptions()
        }

        return response;
    }

    // const generate2c2pPaymentLink = async () => {

    //     let invoiceNo = moment().format("MMDDYY") + randomNumber(6) + randomCode(6);
    //     localStorage.setItem("invoiceNo", invoiceNo);
    //     var response;

    //     try {
    //         let data = {
    //             invoiceNo,
    //             data: props.data,
    //             price_type: props.price_type,
    //             phone: props.phoneNo
    //         };

    //         response = await PaymentServices.generate2c2pPaymentLink(data);

    //     } catch (error) {
    //         console.error(error);
    //         enableAllPaymentOptions()
    //     }

    //     return response;

    // };

    // const getWavePaymentLinkData = async () => {

    //     let invoiceNo = moment().format("MMDDYY") + randomNumber(6) + randomCode(6);
    //     localStorage.setItem("invoiceNo", invoiceNo);

    //     var response;

    //     try {

    //         let data = {
    //             invoiceNo,
    //             data: props.data,
    //             price_type: props.price_type,
    //             phone: props.phoneNo
    //         };

    //         response = await PaymentServices.generateWavePaymentLinkMeta(data);

    //     } catch (error) {
    //         console.error(error);
    //         enableAllPaymentOptions();
    //     }

    //     return response;

    // };

    // On Payment Create web socket code
    useEffect(() => {

        const socket = io(`${process.env.REACT_APP_API_URL}/api/socket`, {
            transports: ["websocket"]
        });

        socket.on("payment_updated", async () => {
            let response = await PaymentServices.isPaymentVerified();
            if (response.data.response['respDesc'] === 'SUCCESS') {
                props.setIsPaymentVerfied(true);
                props.setIsPaymentWindowClosed(true);
                setPaymentRequestLoading(false);
            }

            props.setPaymentData(response.data.response);
        });

        window.addEventListener('message', handleIframeTask);

    }, [])
    // On Payment Create web socket code

    const handleIframeTask = (e) => {
        try {
            if (e.data == 'payment process completed') {
                setShowPaymentModal(false);
                setPaymentRequestLoading(false);
                enableAllPaymentOptions();
            }
        } catch (error) {

        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12 mt-4 mb-4">
                    <div className="card border-0 p-3">
                        <p className="mb-0 fw-bold h4">Make Payment</p>
                    </div>
                </div>
                {/* <div className="col-lg-4 mb-lg-0 mb-3">
                    <button type="button" className="card payment-method-card p-3" ref={officialPartner1BtnRef} onClick={onOfficialPartner1BtnClick}  >
                        <div className="img-box">
                            <img src={require('../../assets/img/flow/wavepay.png').default} alt="wavepay" />
                        </div>
                    </button>
                </div>
                <div className="col-lg-4 mb-lg-0 mb-3">
                    <button type="button" className="card payment-method-card p-3" ref={officialPartner2btnRef} onClick={onOfficialPartner2BtnClick}>
                        <div className="img-box">
                            <img src={require('../../assets/img/flow/yoma-bank-card.png').default} alt="yoma-bank-card" />
                        </div>
                    </button>
                </div>
                <div className="col-lg-4 mb-lg-0 mb-3">
                    <button type="button" className="card payment-method-card p-3" ref={othersBtnRef} onClick={onOthersBtnClick}>
                        <div className="img-box">
                            <img src={require('../../assets/img/flow/2c2p.png').default} alt="2C2P" />
                        </div>
                    </button>
                </div> */}
                <div className="col-lg-4 mb-lg-0 mb-3">
                    <button type="button" className="card payment-method-card p-3" ref={othersBtnRef} onClick={loadDinger}>
                        <div className="img-box">
                            <img src={require('../../assets/img/flow/dinger.png').default} alt="dinger" />
                        </div>
                    </button>
                </div>
            </div>
            {
                paymentRequestLoading &&
                <div classNameName="loading">
                    <div className="spinner-border circlespinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            <PaymentModal
                showModal={showPaymentModal}
                paymentUrl={paymentUrl}
                hideModal={() => {
                    setPaymentRequestLoading(false);
                    setShowPaymentModal(false);
                    enableAllPaymentOptions();
                }}
            />

            <form ref={formWave} action={wavePaymentURL} method="POST">
                <input type="hidden" name="timeToLiveSeconds" value={waveMetadata.timeToLiveSeconds} />
                <input type="hidden" name="order_id" value={waveMetadata.order_id} />
                <input type="hidden" name="merchant_id" value={waveMetadata.merchant_id} />
                <input type="hidden" name="merchant_reference_id" value={waveMetadata.merchant_reference_id} />
                <input type="hidden" name="frontend_result_url" value={waveMetadata.frontend_result_url} />
                <input type="hidden" name="backend_result_url" value={waveMetadata.backend_result_url} />
                <input type="hidden" name="amount" value={waveMetadata.amount} />
                <input type="hidden" name="payment_description" value={waveMetadata.payment_description} />
                <input type="hidden" name="merchant_name" value={waveMetadata.merchant_name} />
                <input type="hidden" name="items" value={waveMetadata.items} />
                <input type="hidden" name="hash" value={waveMetadata.hash} />
                <input type="hidden" name="time_to_live_in_seconds" value={waveMetadata.timeToLiveSeconds} />
            </form>
        </>
    );
}
export default PaymentMethod;
