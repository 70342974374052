import React, { useEffect, useState } from 'react';
import FLowServices from 'services/Flow';

const UploadComponent = ({ file, question }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (file !== undefined) {
      handleFileUpload();
    }
  }, [file])

  const handleFileUpload = async () => {
    setProgress(0)
    setUploading(true);
    let response = await FLowServices.uploadFile(file);
    question.metadata = { url: response.fileUrl };
    setProgress(100);
    setTimeout(() => {
      setUploading(false);
      question.uploaded = true;
    }, 3000)
  };


  return (
    <div>
      {uploading && (
        <div>
          <progress value={progress} max="100" style={{ width: "100%" }} />
        </div>
      )}
    </div>
  );
};

export default UploadComponent;