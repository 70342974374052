import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// reactstrap components
import { Container } from 'reactstrap'
// core components

import { ConfigContext } from '../Context'
import MainCard from 'components/Cards/MainCard.js'

const ThanksPage = () => {
    const { config } = useContext(ConfigContext)
    const { verificationPipeline } = config

    const mainContent = React.useRef(null)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        document.body.style.background = verificationPipeline?.mainBgColor
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContent.current.scrollTop = 0
        // return () => {
        //   // document.body.style.background = null;
        // };
    }, [config, location])

    return (
        <Container fluid>
            <div className="div-center-align" ref={mainContent}>
                <MainCard
                    logo={verificationPipeline?.logo}
                    cardBackground={verificationPipeline?.cardBackground}
                    title={verificationPipeline?.thanksTitle}
                    description={verificationPipeline?.thanksDescription}
                    buttonColor={verificationPipeline?.buttonColor}
                    onClickStart={() => history.push({ pathname: '/mainFlow' })}
                    thanksPage
                />
            </div>
        </Container>
    )
}

export default ThanksPage
