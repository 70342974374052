import React from 'react'
const CardHeader = (props) => {
    const renderTitle = () => {
        const text = {
            __html: props.title,
        }

        return <span dangerouslySetInnerHTML={text} />
    }

    return (
        <h2
            style={{
                lineHeight: 2,
                fontWeight: '200',
                color: '#323232',
                textAlign: 'center',
            }}
        >
            {renderTitle()}
        </h2>
    )
}

export default CardHeader
