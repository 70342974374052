/*eslint-disable*/
import React, { useContext } from 'react'
import { ConfigContext } from '../../Context'

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    CardImg,
} from 'reactstrap'

const MainCard = (props) => {
    const { config } = useContext(ConfigContext)

    const renderCardText = () => {
        const text = {
            __html: props.description,
        }

        return <span dangerouslySetInnerHTML={text} />
    }
    return (
        <Card
            style={{
                maxWidth: '768px',
                backgroundColor: "#0c1d28",
            }}
            body
            className="text-center"
        >
            <CardImg
                style={{ alignSelf: 'center', height: 200, width: 200, objectFit: 'contain' }}
                top
                src={props.logo}
                alt="Card image cap"
            />

            <CardBody>
                <CardTitle
                    style={{
                        color: '#fff',
                    }}
                    tag="h1"
                >
                    {props.title}
                </CardTitle>

                <CardText
                    style={{
                        color: '#fff',
                        whiteSpace: "pre-line",
                    }}
                >
                    {renderCardText()}
                </CardText>
            </CardBody>

            {props.onClickStart && (
                <Button
                    onClick={props.onClickStart}
                    block
                    style={{
                        backgroundColor: props.buttonColor,
                        borderColor: props.buttonColor,
                        color: '#fff',
                    }}
                >
                    {props.thanksPage ? "MAKE ANOTHER REQUEST" : "START"}
                </Button>
            )}
        </Card>
    )
}

export default MainCard
