import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Container, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import CaptchaModal from 'components/Modals/CaptchaModal';
import ConfirmationModal from "components/Modals/ConfirmationModal";

import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';

import { ConfigContext } from 'Context';
import { toast, ToastContainer } from 'react-toastify';
import FLowServices from 'services/Flow';
import PaymentServices from 'services/Payment';
import {
    TermsAndConditionStep,
    ProductStep,
    PhoneNumberStep,
    ConnectionStep,
    VerifyPoliciesStep,
    VerificationSummaryStep,
    PolicyQuestionsStep,
    PaymentStep
} from 'components/Form-Steps/Steps';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { base64ToUTF8String } from 'helpers/utils';
import { CategoryAndProductStep } from 'components/Form-Steps/Steps';

let radioValue = [];
let checkBoxValue = [];
let isPendingVerificationCheck = false;

const PAYMENT_CONFIG = JSON.parse(base64ToUTF8String(process.env.REACT_APP_PAYMENT_CONFIG));

const Flow = () => {

    const history = useHistory();

    const SHOW_PREV_BTN_STEP = [2, 3, 5];

    const { config } = useContext(ConfigContext);
    const { verificationPipeline } = config;
    const buttonColor = verificationPipeline?.buttonColor;

    const [activeIndex, setActiveIndex] = useState(0);
    const [previousButtonArray, setPreviousButtonArray] = useState(SHOW_PREV_BTN_STEP);
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState([]);

    const [connection, setConnection] = useState([]);
    const [connectionPollingSpinner, setConnectionPollingSpinner] = useState(false);

    const [phoneNo, setPhoneNo] = useState("");
    const [productsDetail, setProductsDetail] = useState([]);
    const [getProductReqLoading, setGetProductReqLoading] = useState(false);

    const [proofData, setProofData] = useState([]);

    const [privacyStatementCaptcha, setPrivacyStatementCaptcha] = useState(false);
    const [isCaptchaModal, setIsCaptchaModal] = useState(false);

    const [policiesList, setPoliciesList] = useState([]);
    const [policiesQuestions, setPoliciesQuestions] = useState([]);

    const [paymentData, setPaymentData] = useState({});
    const [isPaymentVerified, setIsPaymentVerfied] = useState(false);
    const [isPaymentWindowClosed, setIsPaymentWindowClosed] = useState(false);

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(undefined);

    // Product Tabs
    const [activeTab, setActiveTab] = useState('0');

    // Step Form Variables
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState([
        {
            label: 'Privacy',
            isValid: undefined
        },
        {
            label: 'Products',
            isValid: undefined
        },
        {
            label: 'Phone',
            isValid: undefined
        },
        {
            label: 'Connection',
            isValid: undefined
        },
        {
            label: 'Questions',
            isValid: undefined
        },

        {
            label: 'Credential Verification',
            isValid: undefined
        },
        {
            label: 'Payment',
            isValid: undefined
        },
        {
            label: 'Summary',
            isValid: undefined
        }]);

    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const isPreviousStepsValid = steps.slice(0, step).findIndex(currentStep => currentStep.isValid === false) === -1;
    // Step Form Variables

    let pId;

    useEffect(async () => {
        applyThemeCustomization()
        getProducts();
        getCategory();
    }, [config]);

    useEffect(async () => {
        if (isPaymentVerified && isPaymentWindowClosed) {
            submitData();
        }
    }, [paymentData, isPaymentVerified]);

    const applyThemeCustomization = () => {

        // console.log(getComputedStyle(document.documentElement).getPropertyValue('--next-btn-bg','red'));

        document.body.style.background = verificationPipeline?.mainBgColor

        if (buttonColor) {
            document.documentElement.style.setProperty('--next-btn-bg', buttonColor);
            document.documentElement.style.setProperty('--next-btn-border', buttonColor);
        }

    }

    // Set Verifed true in PAYMENT table
    const availPayment = async () => {
        await PaymentServices.setPaymentVerificationAvail();
        localStorage.removeItem("invoiceNo");
    }

    // Verify Pending Verification
    const checkPaymentPendingVerification = async (productResponse) => {

        try {
            let response = await PaymentServices.isPaymentVerified();

            if (response.data.response['respCode'] === 'SUCCESS') {
                let pendingVerificationProducts = response.data.response.productIds.split(",");
                let pendingVerificationPhone = response.data.response.phone;

                setIsPaymentVerfied(true);
                setPhoneNo(pendingVerificationPhone);
                setPaymentData(response.data.response);

                isPendingVerificationCheck = false;

                productResponse?.map((item) => {
                    if (pendingVerificationProducts.includes(item._id)) {
                        validateSelectedProduct({ checked: true }, item);
                    }
                });

                setStep(7);
            }
        } catch (error) {
            console.log(error);
        }

    }
    // Verify Pending Verification

    const startGetConnectionPolling = () => {
        let startTime = Date.now();
        pId = setInterval(validateConnection, 5000, startTime);
    }

    const cancelGetConnectionPolling = () => {
        // let timePassed = Date.now() - pollingStartTimeState;
        clearInterval(pId);
    };

    const validateConnection = async (pollingStartTime) => {
        try {
            let timePassed = (Date.now() - pollingStartTime) / 1000;

            // Polling Expired 
            if (timePassed >= 170) {
                cancelGetConnectionPolling();
                setConnectionPollingSpinner(false)
            }

            let phoneConnResponse = await FLowServices.getConnectionByPhone(phoneNo);

            if (phoneConnResponse.connection && phoneConnResponse.connection.length == 1) {
                cancelGetConnectionPolling()
                setConnection(phoneConnResponse.connection[0])
                JSON.parse(process.env.REACT_APP_PAYMENT_ENABLE) ? setStep(step + 2) : setStep(step + 3);
                console.log("JSON.parse(process.env.REACT_APP_PAYMENT_ENABLE)", JSON.parse(process.env.REACT_APP_PAYMENT_ENABLE))
            }

        } catch (error) {
            console.error({ error });
        }
    };

    // This function runs when payment is verified.
    const submitData = async () => {
        let qns = {};
        policiesQuestions.forEach(e => {
            if (e.key) {
                qns = { ...qns, [e.key]: e.answer }
            }
        })

        let data = {
            Phone: phoneNo,
            connectionId: connection.connectionId,
            auto_issuer_cred_type: productsDetail[0].credType,
            ...qns,
            products: productsDetail,
            proofs: proofData,
            questions: policiesQuestions
        }

        await FLowServices.storeUserData(data);
        setStep(7);
    }

    // This function runs when user click on submit button and there is no payment.
    const storeData = async () => {

        let qns = {};
        policiesQuestions.forEach(e => {
            if (e.key) {
                qns = { ...qns, [e.key]: e.answer }
            }
        })

        let data = {
            Phone: phoneNo,
            connectionId: connection.connectionId,
            auto_issuer_cred_type: productsDetail[0].credType,
            ...qns,
            products: productsDetail,
            proofs: proofData,
            questions: policiesQuestions
        }

        if (!isPaymentVerified) {
            await FLowServices.storeUserData(data);
        }

        clearPreviousPaymentState();

        history.push({ pathname: '/thankYou' });

    }

    const getProducts = async () => {

        setGetProductReqLoading(true);

        let productResponse = await FLowServices.getProducts();

        setGetProductReqLoading(false);
        setProducts(productResponse);

        if (localStorage.getItem('invoiceNo')) {
            checkPaymentPendingVerification(productResponse);
        }
    }

    const getCategory = async () => {
        let categoryResponse = await FLowServices.getCategory();
        setCategory(categoryResponse)
    }

    const getConnection = async () => {

        let phoneConnResponse = await FLowServices.getConnectionByPhone(phoneNo);

        if (phoneConnResponse.connection && phoneConnResponse.connection.length == 0) {
            startGetConnectionPolling();
            setConnectionPollingSpinner(true);
            setStep(3);
        }
        if (phoneConnResponse.connection && phoneConnResponse.connection.length == 1) {
            setConnection(phoneConnResponse.connection[0]);
            setStep((isPaymentVerified && JSON.parse(process.env.REACT_APP_PAYMENT_ENABLE)) ? 5 : 4);
        }
    }

    const clearSelectedProducts = () => {
        radioValue = [];
        checkBoxValue = [];
        setProductsDetail([]);
    }

    const clearPreviousPaymentState = () => {
        clearSelectedProducts();
        setPaymentData({});
        setIsPaymentVerfied(false);
        setIsConfirmationModalOpen(false);
        setPhoneNo('');
        localStorage.removeItem("invoiceNo");
        setStep(0);
    }

    const validateSelectedProduct = async ({ checked }, selectedProduct) => {

        let allPolicies = [];
        let allQuestions = [];
        let selectedOptions = [];

        if (selectedProduct.type == 'radio' && checked) {
            radioValue = [selectedProduct];
        } else if (selectedProduct.type == 'checkbox' && checked) {
            checkBoxValue.push(selectedProduct);
        } else if (selectedProduct.type == 'checkbox' && !checked) {
            checkBoxValue = checkBoxValue?.filter(object => { return object._id !== selectedProduct._id; });
        }

        selectedOptions = [...radioValue, ...checkBoxValue].filter((v, i, a) => a.findLastIndex(v2 => (v2._id === v._id)) === i);

        selectedOptions.map((item) => {
            allPolicies = [...allPolicies, ...item.policies].filter((v, i, a) => a.findIndex(v2 => (v2._id === v._id)) === i);
            allQuestions = [...allQuestions, ...item.questions].filter((v, i, a) => a.findIndex(v2 => (v2._id === v._id)) === i);
        });

        setProductsDetail(selectedOptions);
        setPoliciesList(allPolicies);
        setPoliciesQuestions(allQuestions);

    }
    
    const CategoryOrProductStep = process.env.REACT_APP_IS_SEPARATE_CATEGORY_STEP === "true" ?
        <CategoryAndProductStep config={config} activeTab={activeTab} setActiveTab={setActiveTab} productsDetail={productsDetail} clearSelectedProducts={clearSelectedProducts} products={products} category={category} validateSelectedProduct={validateSelectedProduct} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
        : <ProductStep config={config} activeTab={activeTab} setActiveTab={setActiveTab} productsDetail={productsDetail} clearSelectedProducts={clearSelectedProducts} products={products} category={category} validateSelectedProduct={validateSelectedProduct} />

    const stepPages = [
        <TermsAndConditionStep config={config} setIsConfirmationModalOpen={setIsConfirmationModalOpen} getConnection={getConnection} isPaymentVerified={isPaymentVerified} setIsCaptchaModal={setIsCaptchaModal} setPrivacyStatementCaptcha={setPrivacyStatementCaptcha} />,
        CategoryOrProductStep,
        <PhoneNumberStep config={config} phoneNo={phoneNo} setPhoneNo={setPhoneNo} />,
        <ConnectionStep config={config} connectionPollingSpinner={connectionPollingSpinner} getConnection={getConnection} />,
        <PolicyQuestionsStep isPaymentVerified={isPaymentVerified} setIsConfirmationModalOpen={setIsConfirmationModalOpen} policiesQuestions={policiesQuestions} setPoliciesQuestions={setPoliciesQuestions} />,
        <VerifyPoliciesStep config={config} proofData={proofData} availPayment={availPayment} setProofData={setProofData} policiesList={policiesList} connection={connection} />,
        <PaymentStep config={config} phoneNo={phoneNo} productsDetail={productsDetail} setPaymentData={setPaymentData} setIsPaymentVerfied={setIsPaymentVerfied} setIsPaymentWindowClosed={setIsPaymentWindowClosed} setIsConfirmationModalOpen={setIsConfirmationModalOpen} />,
        <VerificationSummaryStep config={config} isPaymentVerified={isPaymentVerified} proofData={proofData} productsDetail={productsDetail} policiesQuestions={policiesQuestions} connection={connection} />,
    ];

    useEffect(async () => {

        const currentSteps = steps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index < step ? true : currentStep.isValid
        }));

        setSteps(currentSteps);

        if (step == 4) {
            if (!policiesQuestions.length) {
                setStep(5)
            }
        }

        if (step == 5) {
            if (!policiesList.length) {
                setStep(6)
            }
            let newArr = previousButtonArray.filter((item) =>
                item !== 5
            );
            setPreviousButtonArray(newArr);
        }

        if (step == 6) {
            if (getTotal() == 0) {
                setStep(7);
            }
        }

        if (step == 5 && process.env.REACT_APP_POLICIES_ENABLE === 'false') {
            setStep(6);
        }
    }, [step]);

    const onStepSubmit = useCallback(event => {

        if (validate()) {
            setStep(() => Math.min(step + 1, lastStepIndex));
        }

        if (isLastStep && isPreviousStepsValid) {
            storeData();
        }

    }, [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex, privacyStatementCaptcha, policiesList, phoneNo, proofData, policiesQuestions, productsDetail]);

    const onPrevClick = useCallback(event => {
        event.preventDefault();
        setStep(() => Math.max(step - 1, 0));
    }, [step, setStep]);

    const getTotal = () => {
        const PRICE_TYPE = PAYMENT_CONFIG?.PAYMENT_PRICE_TYPE;
        return PRICE_TYPE == "PRODUCT" ? productsDetail.reduce((a, b) => a + (b.price || 0), 0) : PAYMENT_CONFIG?.PAYMENT_AMOUNT;
    }

    const policiesQuestionsValidation = () => {

        for (const key in policiesQuestions) {
            if (policiesQuestions[key].mandatory && !policiesQuestions[key].answer) {
                return 'Please Enter All Mandatory Question';
            }
            if (policiesQuestions[key].answer && policiesQuestions[key].pattern) {
                let isMatched = policiesQuestions[key].answer.match(policiesQuestions[key].pattern);
                if (isMatched === null) {
                    return 'Please Enter "' + policiesQuestions[key].question + '" In Correct Format.';
                }
            }
            if (policiesQuestions[key].uploaded === false) {
                return 'Please Wait for file uploads to complete.';
            }
        }

        return '';

    }

    const policiesVerificationValidation = () => {

        let verifiedPolicyIds = proofData.map((item) => { return Object.keys(item)[0] });

        for (const key in policiesList) {
            if (!policiesList[key].isOptional && !verifiedPolicyIds.includes(policiesList[key].policyId)) {
                return false;
            }
        }

        return true;
    }

    function validate() {

        if (step === 0 && !privacyStatementCaptcha) {
            toast.error(`Captcha Required`);
            return false;
        }

        if (step === 1 && productsDetail.length === 0) {
            toast.error(`Please Select Product`);
            return false;
        }

        if (step === 2 && !phoneNo) {
            toast.error(`Please Enter Phone No.`);
            return false;
        }

        if (step === 2 && !isValidPhoneNumber(phoneNo)) {
            toast.error(`Invalid phone number.`);
            return false;
        }

        if (step === 2) {
            getConnection();
            return false;
        }

        if (step === 3) {
            return false;
        }

        // if (step === 4 && isPaymentVerified) {
        //     toast.error(`Please Payment`);
        //     return false;
        // }

        if (step === 4) {
            let error = policiesQuestionsValidation();
            if (error !== '') {
                toast.error(error);
                return false;
            }
        }

        if (step === 5 && !policiesVerificationValidation()) {
            toast.error(`Please Verify All Mandatory Policy`);
            return false;
        }

        return true;
    }

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 15,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: "#1274AC",
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: "#1274AC",

            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                'white',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: 'white',
        color: 'black',
        zIndex: 1,
        width: 30,
        height: 30,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor:
                '#1274AC',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor:
                '#1274AC',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            completed: <Check className='text-white' />,
            active: <div className='text-white'>{props.icon}</div>,
            pending: <div>{props.icon}</div>,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[props.completed ? "completed" : props.active ? "active" : "pending"]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <>
            <ToastContainer />

            {
                getProductReqLoading &&
                <div className="loading">
                    <div className="spinner-border circlespinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            <Container fluid className={activeIndex == 1 || activeIndex == 3 || activeIndex == 5 ? 'qr-code-section-style' : ''}>
                <div className="header pt-4 pb-5 text-center">
                    <img className="img-logo" src={config.verificationPipeline?.logo} />
                </div>
                <div className='form-stepper-wrapper'>
                    <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
                        {steps.map((obj) => (
                            <Step className='step' key={obj.label}>
                                <StepLabel className='step-label' StepIconComponent={ColorlibStepIcon}><div className='step-text'>{obj.label}</div></StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                {step !== 0 && step !== 1 ?
                    <div className='back-button' onClick={onPrevClick}>
                        {`< Back`}
                    </div> : undefined}
                <div className="div-center-align" >
                    <div className='form-wrapper'>
                        <div style={{ alignSelf: 'center' }}>
                            {stepPages[step]}
                            <span style={{ marginTop: '40px' }} className={'k-form-separator'} />
                            <div style={{ justifyContent: 'space-between', alignContent: 'center' }} className={'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}>

                                {step === 0 && <span style={{ alignSelf: 'center' }}></span>}
                                {step !== 0 ?
                                    previousButtonArray.includes(step) && <Button style={{ width: '100%' }} className='btn-blue previous-button' onClick={onPrevClick}>
                                        Previous
                                    </Button> : undefined}
                                {
                                    ![6].includes(step) && <Button style={{ width: '100%' }} className='btn-blue' disabled={(isLastStep ? !isPreviousStepsValid : false) || (step == 0 && isPaymentVerified)} onClick={onStepSubmit}>
                                        {isLastStep ? !JSON.parse(process.env.REACT_APP_PAYMENT_ENABLE) || getTotal() === 0 ? "Submit" : "Finish" : "Next"}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <CaptchaModal isCaptchaModal={isCaptchaModal} setIsCaptchaModal={setIsCaptchaModal} setPrivacyStatementCaptcha={setPrivacyStatementCaptcha} />
            </Container>

            {/* Clear Previous Payment Data Confirmation */}
            <ConfirmationModal
                confirmModal={clearPreviousPaymentState}
                showModal={isConfirmationModalOpen}
                hideModal={() => { setIsConfirmationModalOpen(false) }}
                headerText="Confirmation"
                alertClass='alert-danger'
                message="Are you sure you want to clear your data because this action cannot be undone! "
                proceedBtnText="Clear"
            />
        </>
    )
}

export default Flow;
