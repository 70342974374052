var moment = require('moment');

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
export function intersperse(arr, sep) {
    if (arr.length === 0) {
        return [];
    }

    return arr.slice(1).reduce(function (xs, x, i) {
        return xs.concat([sep, x]);
    }, [arr[0]]);
}

export function isNullOrWhitespace(input) {
    return !input || !input.trim();
}

var timeFormats = [
    "YYYY-MM-DD[T]HH:mm:ss.SSSZ",
    "MM-DD-YYYY[T]HH:mm:ss.SSSZ",
    "DD-MM-YYYY[T]HH:mm:ss.SSSZ",
    // with forward slash
    "YYYY/MM/DD HH:mm:ss",
    "MM/DD/YYYY HH:mm:ss",
    "DD/MM/YYYY HH:mm:ss",
    // with hyphen
    "YYYY-MM-DD HH:mm:ss",
    "MM-DD-YYYY HH:mm:ss",
    "DD-MM-YYYY HH:mm:ss",
];

var dateFormats = [
    // with forward slash
    "YYYY/MM/DD",
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    // with hyphen
    "YYYY-MM-DD",
    "MM-DD-YYYY",
    "DD-MM-YYYY",
];

const is_date_time = (val) => {
    if (!moment(val, timeFormats, true).isValid()) return false

    return true
}

const is_date = (val) => {
    if (!moment(val, dateFormats, true).isValid()) return false

    return true
}

const get_local_issue_time = (val) => {
    return moment
        .utc(val, timeFormats)
        .local()
        .format('YYYY-MM-DD HH:mm (G[M]T Z)')
        .toString();
};

export function parse_date_time(val) {

    if (is_date(val)) {
        return moment(val).format('YYYY-MM-DD');
    }

    if (is_date_time(val)) {
        return get_local_issue_time(val);
    }

    return val;
}

export function randomCode(length) {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function randomNumber(length) {
    let chars = "0123456789";
    var result = "";
    for (var i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}


export function base64ToUTF8String(str) {
    return Buffer.from(str, 'base64').toString('utf8')
}

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}