import 'typeface-roboto';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { ConfigContext } from 'Context.js'
import Flow from 'views/Flow.js'
import ThanksPage from 'layouts/ThanksPage'
import LandingPage from 'layouts/LandingPage'
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';


function App() {
    const [config, setConfig] = useState({})
    const [serverConfig, setServerConfig] = useState({})

    useEffect(() => {
        const _getConfig = async () => {
            const result = await axios.get(
                process.env.REACT_APP_CONFIG_JSON_FILE
            )
            setConfig(result.data.client)
            setServerConfig(result.data.server)
        }
        _getConfig()
    }, [setConfig])

    return (
        <div className="App">
            <ConfigContext.Provider value={{ config , serverConfig }}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route exact path="/mainFlow" component={Flow} />
                        <Route exact path="/thankYou" component={ThanksPage} />
                    </Switch>
                </Router>
            </ConfigContext.Provider>
        </div>
    )
}

export default App
