import Auth from 'helpers/Auth';
import axios from 'axios'

class FLowServicesClass {
    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Auth.getToken()
        };
    }

    updateHeaders = () => {
        this.headers["Authorization"] = 'Bearer ' + Auth.getToken()
    };

    getProducts = () => {
        this.updateHeaders()
        return new Promise((resolve, reject) => {
            try {
                var PRODUCTS_API_PATH = process.env.REACT_APP_API_URL + '/api/get_products'

                fetch(PRODUCTS_API_PATH, {
                    method: 'GET',
                    headers: this.headers
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.status === 200) {
                            resolve(json.products)
                        }
                    })
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    getConnectionByPhone = async (phone) => {
        this.updateHeaders()
        return new Promise((resolve, reject) => {
            try {
                const CONNECTIONS_API_PATH = `${process.env.REACT_APP_API_URL}/api/search_connection_phone?phone=${phone}`

                fetch(CONNECTIONS_API_PATH, {
                    method: 'GET',
                    headers: this.headers
                })
                    .then((res) => res.json())
                    .then((json) => {
                        resolve(json)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    sendVerificationRequest = async (policyId, userConnectionId) => {
        this.updateHeaders()
        return new Promise((resolve, reject) => {
            try {
                const VERIFY_API_PATH = `${process.env.REACT_APP_API_URL}/api/verify`

                axios.post(VERIFY_API_PATH,
                    {
                        connectionId: userConnectionId,
                        subject: localStorage.getItem('subject'),
                        policyId: policyId
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    getVerificationDetails = async (verificationId) => {
        this.updateHeaders()
        return new Promise((resolve, reject) => {
            try {
                const GET_VERIFICATION_API_PATH = `${process.env.REACT_APP_API_URL}/api/get-verification-details`

                axios.get(GET_VERIFICATION_API_PATH,
                    {
                        params: { verificationId: verificationId },
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    storeUserData = async (data) => {
        this.updateHeaders()
        return new Promise((resolve, reject) => {
            try {
                const STORE_USER_DATA_API_PATH = `${process.env.REACT_APP_API_URL}/api/store_user_data`

                axios.post(STORE_USER_DATA_API_PATH,
                    data,
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    getCategory = () => {
        this.updateHeaders()
        return new Promise((resolve, reject) => {
            try {
                var CATEGORY_API_PATH = process.env.REACT_APP_API_URL + '/api/get_category'

                fetch(CATEGORY_API_PATH, {
                    method: 'GET',
                    headers: this.headers
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.status === 200) {
                            resolve(json.category)
                        }
                    })
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    uploadFile = (file) => {
        this.updateHeaders()
        let headers = { ...this.headers, 'Content-Type': "multipart/form-data" }
        return new Promise(async (resolve, reject) => {
            try {
                var UPLOAD_FILE_API_PATH = process.env.REACT_APP_API_URL + '/api/upload_file'
                const formData = new FormData();
                formData.append('file', file);
                axios(UPLOAD_FILE_API_PATH, {
                    method: 'POST',
                    data: formData,
                    headers: headers
                })
                    .then(async (res) => {
                        if (res.status === 200) {
                            resolve(res.data)
                        } else {
                            reject(res)
                        }
                    })
                    .catch((err) => ("Error occured", err));
            } catch (error) {
                reject(error)
                console.log(error)
            }
        })
    }
}

const FLowServices = new FLowServicesClass;

export default FLowServices;
