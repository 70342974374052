import { useState, useEffect } from "react"
import { Spinner } from "reactstrap"
import ReactModal from "react-modal";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import FLowServices from 'services/Flow';

const ButtonVerify = (props) => {

    const [sendRequestLoader, setSendRequestLoader] = useState(false);

    const [isPolicyVerified, setIsPolicyVerified] = useState(false)

    //Polling States 
    const [pollingIdState, setPollingIdState] = useState(0);
    const [pollingStartTimeState, setPollingStartTimeState] = useState(0);
    let pollingId = 0;
    let pollingStartTime = 0;

    const [modalAcceptedIsOpen, setModalAcceptedIsOpen] = useState(false);
    const [modalRejectedIsOpen, setModalRejectedIsOpen] = useState(false);
    const [modalPollingIsOpen, setModalPollingIsOpen] = useState(false);

    useEffect(async () => {
        let verifiedPolicyIds = props.proofData.map((item) => { return Object.keys(item)[0] });

        if (verifiedPolicyIds.includes(props.policy.policyId)) {
            setIsPolicyVerified(true);
        }
    }, [])

    function closeModal() {
        setModalAcceptedIsOpen(false);
        setModalRejectedIsOpen(false);
    }

    const getAttributes = (proof) => {
        let attributes = '';

        for (let key in proof) {
            let value = proof[key];
            if (typeof value === 'object' && value.attributes) {
                attributes = value.attributes;
                break
            }
        }

        return attributes;
    }

    const validateVerification = async (verificationId) => {
        try {

            let timePassed = (Date.now() - pollingStartTime) / 1000;

            if (timePassed >= 300) {
                cancelPolling();
            }

            let getVerificationResp = await FLowServices.getVerificationDetails(verificationId);

            if (getVerificationResp.success) {
                if (getVerificationResp.verification.state === "Accepted") {
                    cancelPolling();
                    props.setIsVerificationSend(false);
                    setIsPolicyVerified(true);

                    let PolicyId = props.policy.policyId;
                    let proof = getVerificationResp.verification.proof;
                    let proofsObj = {
                        [PolicyId]:
                        {
                            verificationId: getVerificationResp.verification.verificationId,
                            policyName: getVerificationResp.verification.policy.name,
                            data: getAttributes(proof),
                        }
                    }

                    props.setProofData((proofData) => [...proofData, proofsObj]);

                }
            }

        } catch (error) {
            console.error({ error });
        }
    };

    const startPolling = async (verificationId) => {
        let startTime = Date.now();
        let pId = setInterval(validateVerification, 8000, verificationId);
        pollingId = pId;
        pollingStartTime = startTime;
        setPollingIdState(pId)
        // setPollingStartTimeState(startTime)
    }

    const cancelPolling = async () => {
        console.log('Polling Cancel');
        let timePassed = Date.now() - pollingStartTimeState;
        clearInterval(pollingIdState);
        clearInterval(pollingId);
    };

    const HandlePolicyClick = async (policyId, userConnectionId) => {

        toast.success(`Please wait while your data is being verified`);
        setSendRequestLoader(true);
        props.setIsVerificationSend(true);
        props.setIsVerificationAvail(true);
        let verificationResp = await FLowServices.sendVerificationRequest(policyId, userConnectionId);
        let verificationId = verificationResp.result.verificationId;

        startPolling(verificationId);

    }

    const HandleRefreshPage = () => {
        cancelPolling();
        setSendRequestLoader(false);
        props.setIsVerificationSend(false)
    }

    return (<>

        <ReactModal
            shouldCloseOnOverlayClick={false}
            backdrop="static"
            isOpen={modalPollingIsOpen}
            // onRequestClose={closeModal}
            contentLabel="My dialog"
            className="qrmodal"
            overlayClassName="qroverlay"
        >
            <p>Verification Request is Sent, Please Accept!</p>
            <br />
            <div className="centered-content d-flex justify-content-center">
                <div id="spinner" className="spinner-border centered-content" role="status">
                    <span className="sr-only centered-content">Loading...</span>
                </div>
            </div>
            <br />

            <div className="text-center">
                <Button
                    color="primary"
                    onClick={() => {
                        setModalPollingIsOpen(false)
                        cancelPolling()
                    }}
                >
                    Cancel
                </Button>
            </div>
        </ReactModal>

        <ReactModal
            shouldCloseOnOverlayClick={false}
            backdrop="static"
            isOpen={modalAcceptedIsOpen}
            contentLabel="My dialog"
            className="qrmodal"
            overlayClassName="qroverlay">

            <div className="centered-content d-flex justify-content-center">
                <h4>Verification Status</h4>
            </div>
            <br />
            <div className="centered-content d-flex justify-content-center">
                <p>Verification request is accepted.</p>
            </div>
            <div className="centered-content d-flex justify-content-center">
                <img
                    alt="..."
                    height="64px"
                    src={
                        require("../../assets/img/theme/check.png")
                            .default
                    }
                />
            </div>
            <div>
                {/* {Object.entries(credential).map(([key, value]) => {
                        return <div><span>{key}</span>:{" "}<span>{value}</span></div>
                    })} */}
            </div>
            <br />
            <Button
                color="success"
                style={{ width: "100%" }}
                onClick={closeModal}
            >
                Close
            </Button>
        </ReactModal>

        <ReactModal
            shouldCloseOnOverlayClick={false}
            backdrop="static"
            isOpen={modalRejectedIsOpen}
            contentLabel="My dialog"
            className="qrmodal"
            overlayClassName="qroverlay">

            <div className="centered-content d-flex justify-content-center">
                <h4>Verification Issuance Failed</h4>
            </div>
            <br />
            <div className="centered-content d-flex justify-content-center">
                <p>Verification request is rejected.</p>
            </div>
            <div className="centered-content d-flex justify-content-center">
                <img
                    alt="..."
                    height="96px"
                    src={
                        require("../../assets/img/theme/cross.png")
                            .default
                    }
                />
            </div>
            <br />
            <Button

                style={{ width: "100%", backgroundColor: "#c82606", color: "#ffffff" }}
                onClick={closeModal}
            >
                Continue
            </Button>
        </ReactModal>

        {!isPolicyVerified &&
            <div>
                {!sendRequestLoader && <button id={props.id} style={{ width: "100px" }}
                    type="button"
                    disabled={props.isVerificationSend}
                    className="btn btn-info btn-sm policy-verify-button"
                    onClick={() => { HandlePolicyClick(props.policy.policyId, props.connection.connectionId) }}
                >
                    Verify
                </button>
                }
                {sendRequestLoader && <button id={props.id} style={{ width: "100px" }}
                    type="button"
                    className="btn btn-danger btn-sm policy-verify-button"
                    onClick={HandleRefreshPage}
                >
                    Cancel
                    {sendRequestLoader && <Spinner animation="border" size={'sm'} variant="info" />}
                </button>
                }
            </div>
        }
        {isPolicyVerified &&
            <p style={{ width: "100px", textAlign: "center", margin: 0, color: "green", border: "1px solid green" }} className="policy-verify-button">
                Verified ✓
            </p>
        }

    </>)
}
export default ButtonVerify;