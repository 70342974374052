import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// reactstrap components
import { Container } from 'reactstrap'
import { ConfigContext } from '../Context'
import MainCard from 'components/Cards/MainCard.js'

const LandingPage = () => {
    const history = useHistory()

    const { config } = useContext(ConfigContext)
    const {
        verificationPipeline,
    } = config

    const mainContent = React.useRef(null)
    const location = useLocation()

    React.useEffect(() => {
        document.body.style.background = verificationPipeline?.mainBgColor
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContent.current.scrollTop = 0
    }, [config, location])

    return (
        <Container fluid>
            <div className="div-center-align" ref={mainContent}>
                <MainCard
                    title={verificationPipeline?.title}
                    description={verificationPipeline?.description}
                    logo={config.verificationPipeline?.logo}
                    step={8}
                    cardBackground={verificationPipeline?.cardBackground}
                    buttonColor={verificationPipeline?.buttonColor}
                    onClickStart={() => history.push({ pathname: '/mainFlow' })}
                />
            </div>
        </Container>
    )
}

export default LandingPage
